import React from 'react'
import { isNotNilOrEmpty } from '@solta/ramda-extra'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import { Menu } from './Menu'

const { string, func, oneOf } = PropTypes

function MenuField({
  name,
  onSelected,
  label,
  selectionMode,
  // Allow client to select value based on new key
  valueSelector = (newKey) => newKey,
  displayTextSelector = (newKey) => newKey,
  errorMsgSelector = (error) => error,
  ...props
}) {
  const [{ value: selectedValue }, { touched, error }, { setValue }] = useField({
    name,
  })

  const defaultOnSelected = (newKey) => {
    const newValue = valueSelector(newKey)

    setValue(newValue)
  }

  const hasError = touched && isNotNilOrEmpty(error)

  return (
    <Menu
      name={name}
      error={errorMsgSelector(error)}
      label={label}
      selectedValue={selectedValue}
      selectionMode={selectionMode}
      displayTextSelector={displayTextSelector}
      hasError={hasError}
      onAction={
        onSelected
          ? (newKey) => onSelected(newKey, valueSelector(newKey))
          : defaultOnSelected
      }
      {...props}
    />
  )
}

MenuField.propTypes = {
  name: string.isRequired,
  onAction: func.isRequired,
  selectionMode: oneOf(['none', 'single', 'multiple']),
}

export { MenuField }
