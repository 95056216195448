/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unused-vars */
import React from 'react'
import { useOverlayTriggerState } from '@react-stately/overlays'
import {
  useOverlay,
  useOverlayTrigger,
  useOverlayPosition,
  useModal,
  OverlayProvider,
  OverlayContainer,
  DismissButton,
} from '@react-aria/overlays'
import { useDialog } from '@react-aria/dialog'
import { FocusScope } from '@react-aria/focus'
import { useButton } from '@react-aria/button'
import { mergeProps } from '@react-aria/utils'

import { mergeDeepRight } from '@solta/ramda-extra'

import { s, styled } from '@vega/styled'
import { ReactComponent as DropdownIcon } from '@vega/components/src/assets/images/arrow_drop_down-24px.svg'

const Button = styled.button(
  s(
    'flex items-center justify-center bg-white border-solid border-1 border-grey-200 py-3 px-4 text-sm text-grey-600 rounded-lg h-full',
    { whiteSpace: 'nowrap', outline: 'none', '&:hover': { cursor: 'pointer' } }
  )
)

const PopoverContainer = styled.div(s('p-4', { outline: 'none' }))

const Popover = React.forwardRef(
  ({ title, children, isOpen, onClose, style, ...otherProps }, ref) => {
    const { overlayProps } = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: true,
      },
      ref
    )

    const { modalProps } = useModal()

    const { dialogProps, titleProps } = useDialog({}, ref)

    return (
      <FocusScope restoreFocus>
        <PopoverContainer
          {...mergeProps(overlayProps, dialogProps, otherProps, modalProps)}
          ref={ref}
          style={style}
        >
          {children}
          <DismissButton onDismiss={onClose} />
        </PopoverContainer>
      </FocusScope>
    )
  }
)

function Overlay({ children, label, popoverStyle }) {
  const state = useOverlayTriggerState({})

  const triggerRef = React.useRef()
  const overlayRef = React.useRef()

  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: 'dialog' },
    state,
    triggerRef
  )

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement: 'bottom',
    crossOffset:
      (triggerRef.current?.offsetWidth - overlayRef.current?.offsetWidth) / 2 || 0,
    offset: 4,
    isOpen: state.isOpen,
  })

  const { buttonProps } = useButton(
    {
      onPress: () => state.toggle(),
    },
    triggerRef
  )

  return (
    <OverlayProvider>
      <Button {...buttonProps} {...triggerProps} ref={triggerRef}>
        {label}
        <DropdownIcon style={s('w-1 h-1 ml-1')} fill={s('text-grey-400').color} />
      </Button>
      {state.isOpen && (
        <OverlayContainer>
          <Popover
            {...overlayProps}
            {...positionProps}
            ref={overlayRef}
            isOpen={state.isOpen}
            onClose={state.close}
            {...mergeDeepRight(positionProps, { style: popoverStyle })}
          >
            {children}
          </Popover>
        </OverlayContainer>
      )}
    </OverlayProvider>
  )
}

export default Overlay
